import $ from 'jquery';
import './scss/style.scss';
import 'slick-carousel/slick/slick.css'
import slick from 'slick-carousel'

import Swiper from 'swiper';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import 'swiper/swiper.css';

// Add interview name
const interviewName = $('.answer').attr('data-name');
$('.answer').each( function() {
  $( this ).find( "p:first-of-type" ).prepend(`<span class="dropcap" style="font-weight: 700;">${interviewName}</span>`);
});

 
// Tabs
const tabBtn = $('.collection-tabs-navigation button')
const tabItem = $('.collection-tabs-content-item')

$('.collection-tabs-navigation button[data-tab="1"]').addClass('active')
$('.collection-tabs-content-item[data-index="1"]').addClass('active')

tabBtn.on('click', function(){
  const index = $(this).attr('data-tab')
  $(this).parent().find('button').removeClass('active')
  $(this).addClass('active')
  $(this).parent().parent().find('.collection-tabs-content-item').removeClass('active')
  $(this).parent().parent().find(`.collection-tabs-content-item[data-index="${index}"]`).addClass('active')
})

// Open and close share
const shareBtn = $('.dots')
shareBtn.on('click', function(){
  const has = $(this).parent().hasClass('active')
  if (has) {
    $(this).parent().removeClass('active')
  } else {
    $(this).parent().addClass('active')
  }
})

// Open and close menu
const menuOpen = $('.menu-open')
const menuClose = $('.menu-close')
const menuModal = $('.menu-modal')
const menuOpenSubMenu = $('.menu .menu-item a[href="#"]')

menuOpen.on('click', function(){
  menuModal.addClass('open')
})

menuClose.on('click', function(){
  menuModal.removeClass('open')
})

menuOpenSubMenu.on('click', function(){
  const menuSubMenu = $(this).parent().find('.sub-menu')
  const has = menuSubMenu.hasClass('open')
  if (has) {
    menuSubMenu.removeClass('open')
    $(this).removeClass('open')
  } else {
    menuSubMenu.addClass('open')
    $(this).addClass('open')
  }
})

// Open and close search
const searchOpen = $('.open-search')
const searchInput = $('.header-menu-search form')

searchOpen.on('click', function(){
  const has = $(this).hasClass('open')
  if(has) {
    $(this).removeClass('open')
    searchInput.removeClass('open')
  } else {
    $(this).addClass('open')
    searchInput.addClass('open')
  }
})

// Carousel
$('.collection-graphic-content .slide').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  cssEase: 'ease-in-out',
  arrows: true,
  dots: false,
  variableWidth: true,
  prevArrow: $('.graphic-slider-nav-prev'),
  nextArrow: $('.graphic-slider-nav-next'),
})

$('.collection-weeknews-content .slide').slick({
  slidesToShow: 2,
  slidesToScroll: 1,
  infinite: false,
  cssEase: 'ease-in-out',
  arrows: true,
  dots: false,
  variableWidth: true,
  prevArrow: $('.weeknews-slider-nav-prev'),
  nextArrow: $('.weeknews-slider-nav-next'),
})

$('.collection-carousel-content .slide').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: false,
  cssEase: 'ease-in-out',
  arrows: true,
  dots: false,
  variableWidth: true,
  prevArrow: $('.carousel-slider-nav-prev'),
  nextArrow: $('.carousel-slider-nav-next'),
})

$('.collection-webstories-content .slide').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: false,
  cssEase: 'ease-in-out',
  arrows: true,
  dots: false,
  variableWidth: true,
  prevArrow: $('.webstories-slider-nav-prev'),
  nextArrow: $('.webstories-slider-nav-next'),
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        variableWidth: false,
      }
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
      }
    },
  ]
})

$('.collection-vertical-content .slide').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: true,
  cssEase: 'ease-in-out',
  arrows: true,
  dots: false,
  prevArrow: $('.slider-nav-prev'),
  nextArrow: $('.slider-nav-next'),
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
      }
    },
  ]
})

$('.page-home-carousel .slider').slick({
  slidesToShow: 2.2,
  slidesToScroll: 1,
  infinite: false,
  cssEase: 'ease-in-out',
  arrows: true,
  dots: false,
  variableWidth: true,
  prevArrow: $('.slider-nav-prev'),
  nextArrow: $('.slider-nav-next'),
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
      }
    },
  ]
})

$('.page-category-webstories-list .list').slick({
  slidesToShow: 4.8,
  slidesToScroll: 1,
  infinite: false,
  cssEase: 'ease-in-out',
  arrows: true,
  dots: false,
  prevArrow: $('.list-nav-prev'),
  nextArrow: $('.list-nav-next'),
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 4.2,
        slidesToScroll: 1,
        variableWidth: false,
      }
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 3.2,
        slidesToScroll: 1,
        variableWidth: false,
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 1,
        variableWidth: false,
      }
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
        variableWidth: false,
      }
    },
  ]
})

$('.page-category-carousel .carousel').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  cssEase: 'ease-in-out',
  arrows: true,
  dots: false,
  prevArrow: $('.carousel-nav-prev'),
  nextArrow: $('.carousel-nav-next'),
})

$('.page-authors-carousel .slider').slick({
  slidesToShow: 4.5,
  slidesToScroll: 1,
  infinite: false,
  cssEase: 'ease-in-out',
  arrows: true,
  dots: false,
  prevArrow: $('.slider-nav-prev'),
  nextArrow: $('.slider-nav-next'),
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 1,
        variableWidth: false,
      }
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
        variableWidth: false,
      }
    },
  ]
})

$('.post-wrapper .carousel-slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  cssEase: 'ease-in-out',
  arrows: true,
  dots: false,
  prevArrow: $('.post-wrapper .carousel-prev'),
  nextArrow: $('.post-wrapper .carousel-next'),
})

const swiper = new Swiper('.collection-carouselimage-content', {
  modules: [Navigation],
  speed: 1500,
  slidesPerView: 'auto',
  spaceBetween: 10,
  navigation: {
    prevEl: '.carouselimage-nav-prev',
    nextEl: '.carouselimage-nav-next',
  }
});

// Progress bar
$(document).on("scroll", function(){
  var pixels = $(document).scrollTop();
  var pageHeight = $(document).height() - $(window).height();
  var progress = 100 * pixels / pageHeight;
  
  $("div.progress").css("width", progress + "%");
})

$(".collection-menu a").click(function() {
  const index = $(this).attr('data-index');

  $('html, body').animate({
    scrollTop: $(`#${index}`).offset().top - 200
  }, 2000);
});
